import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from 'pmt-ui/styles'
import Typography from 'pmt-ui/Typography'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import Actions from './components/Actions'

import { CardBasicActionType } from './constants'

const styles = theme => ({
  title: {
    textAlign: 'left',
  },
  content: {
    padding: `${theme.spacing(2)}px !important`,
  },
})

/**
 * class CardBasic - Render the basic card
 * @params color {string} - define color of card
 * @params header {object} - contains header properties : {
 *   @property avatar {Component} - icon component
 *   @property title {string} - title value
 * }
 * @params content {DOM} - content value
 * @params className {string} - the external class given to the BasicCard component
 */

class CardBasic extends React.Component {
  render() {
    const { color, header, content, actions, className, classes } = this.props

    return (
      <Card className={className}>
        <CardHeader
          style={{
            borderBottom: `4px solid ${color}`,
          }}
          avatar={header.avatar}
          title={
            <Typography variant="h5" className={`${classes.title}`}>
              {header.title}
            </Typography>
          }
        />
        <CardContent className={classes.content}>{content}</CardContent>

        <Actions actions={actions} />
      </Card>
    )
  }
}

CardBasic.ActionType = CardBasicActionType

CardBasic.defaultProps = {
  actions: null,
}

CardBasic.propTypes = {
  /**
   * Header bottom border color
   */
  color: PropTypes.any.isRequired,

  /**
   * - avatar -> icon (TODO: rename?)
   * - title
   */
  header: PropTypes.object.isRequired,

  /**
   * Define the action(s) to put on the header.
   * Can be directly an object, in case of single action, or an array
   *
   * Either:
   * - type (cf CardBasic.ActionType)
   * - onClick
   * - props: props to pass to the component
   *
   * OR
   * - component
   *
   */
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  className: PropTypes.string,

  content: PropTypes.any.isRequired,
}

export default withStyles(styles)(CardBasic)
