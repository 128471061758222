import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import Typography from 'pmt-ui/Typography'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

const styles = theme => ({
  content: {
    padding: `${theme.spacing(2)}px !important`,
  },
})

/**
 * class CardPostIt - Render the basic card
 * @params color {string} - define color of card
 * @params header {object} - contains header properties : {
 *   @property avatar {Component} - icon component
 *   @property title {string} - title value
 * }
 * @params content {DOM} - content value
 * @params cardClass {string} - the external class given to the BasicCard component
 */

class CardPostIt extends React.Component {
  render() {
    const { color, header, content, className, classes } = this.props

    return (
      <Card
        style={{
          color: color.font,
          background: color.background,
          borderLeft: `4px solid ${color.border}`,
        }}
        className={className}
      >
        <CardHeader
          style={{
            borderBottom: `1px solid ${color.border}`,
          }}
          avatar={header.avatar}
          title={
            <Typography
              variant="h6"
              style={{
                textAlign: 'left',
                color: color.font,
              }}
            >
              {header.title}
            </Typography>
          }
        />
        <CardContent className={classes.content}>{content}</CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(CardPostIt)
