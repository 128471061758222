import React from 'react'
import invariant from 'invariant'

import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

import { CardBasicActionType } from '../constants'

import IconButton from 'pmt-ui/IconButton'
import EditorModeEditIcon from 'pmt-ui/svg-icons/editor/mode-edit'


const getCardBasicActionType = (action) => {
  switch (action.type) {
    case CardBasicActionType.EDIT:
      return (
        <IconButton
          aria-label="edit"
          onClick={action.onClick}
          {...action.props}
        >
          <EditorModeEditIcon
          />
        </IconButton>
      )

    default:
      invariant(false, `invalid CardBasicActionType ${action.type}`)
  }
}

const Actions = ({ actions }) => {
  if (actions === null || isEmpty(actions)) {
    return null
  }

  actions = !isArray(actions) ? [ actions ] : actions

  return (
    <div>
      {actions.map((action, index) => (
        <div
          key={index}
        >
          {action.component ? action.component : (
            getCardBasicActionType(action)
          )}
        </div>
      ))}
    </div>
  )
}

export default Actions
