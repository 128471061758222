export default {
  // for key we use the `ENV` from our config
  DEV: {
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-11',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_dev',
        },
        debug: true,
      },
       */
      // GA4
      {
        trackingId: 'G-FMLGRX3VYC',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_dev',
        },
        debug: true,
      },
    ],
    /*
      options.nonce	string Optional Used for Content Security Policy (CSP) more
      options.testMode	boolean Default false
      options.gaOptions	object Optional Reference
      options.gtagOptions	object Optional
      options.legacyDimensionMetric	boolean Default true
      
      */
    options: {
      gtagOptions: { debug_mode: true },
    },
  },
  PREPROD: {
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-12',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_preprod',
        },
      },
       */
      // GA4
      {
        trackingId: 'G-KLSQCN1X9P',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_preprod',
        },
      },
    ],
    options: {},
  },
  PROD: {
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-13',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_prod',
        },
      },
       */
      // GA4
      {
        trackingId: 'G-20MQYG9HSV',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_prod',
        },
      },
    ],
    options: {},
  },
}
